import React, { useState } from "react";
import Layout from "../components/layout.js";
import { Multiselect } from "multiselect-react-dropdown";
import "../styles/styletile.scss";
import { graphql } from "gatsby";
import useCookie from "../hooks/use-cookie";
import { getBrowserLanguage } from "../hooks/get-language";
import { getHeaderAndFooter } from "../hooks/get-header-and-footer.jsx";

const Styletile = ({ data, location }) => {
  let testData = [
    { key: "Option 1", cat: "Group 1" },
    { key: "Option 2", cat: "Group 1" },
    { key: "Option 3", cat: "Group 1" },
    { key: "Option 4", cat: "Group 2" },
    { key: "Option 5", cat: "Group 2" },
    { key: "Option 6", cat: "Group 2" },
    { key: "Option 7", cat: "Group 2" },
  ];

  const [dropdown, setDropdown] = useState({
    key: "",
    name: "",
  });
  const [cookie, setCookie] = useCookie(
    "language",
    getBrowserLanguage(location)
  );

  const { foundHeader, foundFooter } = getHeaderAndFooter(data, cookie);

  return (
    <Layout
      navigation={foundHeader}
      footer={foundFooter}
      dropdown={dropdown}
      setDropdown={setDropdown}
      cookie={cookie}
      setCookie={setCookie}
    >
      <div className="styletile">
        <section className="color-grid">
          <div className="color gray-500">
            <div className="bg"></div>
            <div className="text">
              Gray 500
              <br />
              #282828
            </div>
          </div>
          <div className="color gray-400">
            <div className="bg"></div>
            <div className="text">
              Gray 400
              <br />
              #535353
            </div>
          </div>
          <div className="color gray-300">
            <div className="bg"></div>
            <div className="text">
              Gray 300
              <br />
              #a9a9a9
            </div>
          </div>
          <div className="color gray-200">
            <div className="bg"></div>
            <div className="text">
              Gray 200
              <br />
              #e2e2e2
            </div>
          </div>
          <div className="color gray-100">
            <div className="bg"></div>
            <div className="text">
              Gray 100
              <br />
              #f4f4f4
            </div>
          </div>
          <div className="color green-500">
            <div className="bg"></div>
            <div className="text">
              Green 500
              <br />
              #37423a
            </div>
          </div>
          <div className="color green-400">
            <div className="bg"></div>
            <div className="text">
              Green 400
              <br />
              #47564b
            </div>
          </div>
          <div className="color green-300">
            <div className="bg"></div>
            <div className="text">
              Green 300
              <br />
              #5f7566
            </div>
          </div>
          <div className="color green-200">
            <div className="bg"></div>
            <div className="text">
              Green 200
              <br />
              #bfc8c2
            </div>
          </div>
          <div className="color green-100">
            <div className="bg"></div>
            <div className="text">
              Green 100
              <br />
              #dfe3e0
            </div>
          </div>
          <div className="color brown-500">
            <div className="bg"></div>
            <div className="text">
              Brown 500
              <br />
              #3b2e26
            </div>
          </div>
          <div className="color brown-400">
            <div className="bg"></div>
            <div className="text">
              Brown 400
              <br />
              #4d3a2c
            </div>
          </div>
          <div className="color brown-300">
            <div className="bg"></div>
            <div className="text">
              Brown 300
              <br />
              #846041
            </div>
          </div>
          <div className="color brown-200">
            <div className="bg"></div>
            <div className="text">
              Brown 200
              <br />
              #a27d57
            </div>
          </div>
          <div className="color brown-100">
            <div className="bg"></div>
            <div className="text">
              Brown 100
              <br />
              #f1ece7
            </div>
          </div>
          <div className="color black">
            <div className="bg"></div>
            <div className="text">
              Black
              <br />
              #000000
            </div>
          </div>
          <div className="color white">
            <div className="bg"></div>
            <div className="text">
              White
              <br />
              #ffffff
            </div>
          </div>
          <div className="color error-red">
            <div className="bg"></div>
            <div className="text">
              Error Red
              <br />
              #a51212
            </div>
          </div>
        </section>
        <section className="typeface-grid">
          <div className="fonts">
            <div className="typeface pill-gothic-reg">
              <div className="letters">Aa</div>
              <div className="text">
                Pill Gothic 600mg
                <br />
                Regular
              </div>
            </div>
            <div className="typeface pill-gothic-bold">
              <div className="letters">Aa</div>
              <div className="text">
                Pill Gothic 600mg
                <br />
                Bold
              </div>
            </div>
          </div>
          <div className="usage">
            <h1 className="title-xl">Title XL</h1>
            <h2 className="title-large">Title Large</h2>
            <h3 className="title-medium">Title Medium</h3>
            <h4 className="title-small">Title Small</h4>
            <label className="label-large">Label Large</label>
            <label className="label-medium">Label Medium</label>
            <p className="p-large">
              Paragraph Large: Ski out nisi snowboard exercitation hot tub,
              views trails duis sint ski labore. Duis ski lift adipiscing trails
              ut gondola exercitation ut ullamco ski, tempor mountain biking hot
              tub kitchen. Veniam veniam ad ut ullamco do exercitation duis
              enim. Ad amec ea magna sit ski kitchen eiusmod ipsum, sed ipsum
              snowboard ipsum. Aliqua tempor aliquip ski in vacation, magna
              nostrud ski gondola ut.
            </p>
            <p className="p-large-bold">
              Paragraph Large Bold: Ski out nisi snowboard exercitation hot tub,
              views trails duis sint ski labore. Duis ski lift adipiscing trails
              ut gondola exercitation ut ullamco ski, tempor mountain biking hot
              tub kitchen. Veniam veniam ad ut ullamco do exercitation duis
              enim. Ad amec ea magna sit ski kitchen eiusmod ipsum, sed ipsum
              snowboard ipsum. Aliqua tempor aliquip ski in vacation, magna
              nostrud ski gondola ut.
            </p>
            <p className="p-medium">
              Paragraph Medium: Ski out nisi snowboard exercitation hot tub,
              views trails duis sint ski labore. Duis ski lift adipiscing trails
              ut gondola exercitation ut ullamco ski, tempor mountain biking hot
              tub kitchen. Veniam veniam ad ut ullamco do exercitation duis
              enim. Ad amec ea magna sit ski kitchen eiusmod ipsum, sed ipsum
              snowboard ipsum. Aliqua tempor aliquip ski in vacation, magna
              nostrud ski gondola ut.
            </p>
            <p className="p-small">
              Paragraph Small: Ski out nisi snowboard exercitation hot tub,
              views trails duis sint ski labore. Duis ski lift adipiscing trails
              ut gondola exercitation ut ullamco ski, tempor mountain biking hot
              tub kitchen. Veniam veniam ad ut ullamco do exercitation duis
              enim. Ad amec ea magna sit ski kitchen eiusmod ipsum, sed ipsum
              snowboard ipsum. Aliqua tempor aliquip ski in vacation, magna
              nostrud ski gondola ut.
            </p>
          </div>
        </section>
        <section className="buttons">
          <div className="btns-white-background">
            <button className="button-small btn-light">Button Small</button>
            <button className="button-default btn-light">Button Default</button>
            <button className="button-large btn-light">Button Large</button>
            <a className="cta-small link">CTA Small</a>
            <a className="cta-large link">CTA Large</a>
          </div>
          <div className="btns-black-background">
            <button className="button-small btn-dark">Button Small</button>
            <button className="button-default btn-dark">Button Default</button>
            <button className="button-large btn-dark">Button Large</button>
          </div>
        </section>
        <section className="forms">
          <form>
            <input
              name="inputDefault"
              aria-label="inputDefault"
              class="input"
              type="text"
              placeholder="Input Default"
            />
            <Multiselect
              options={testData}
              placeholder="Select Default"
              singleSelect
              displayValue="key"
            />
          </form>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allContentstackHeader {
      nodes {
        locale
        menu {
          link {
            href
            title
          }
        }
        logo {
          logo_title
          logo_image {
            url
          }
        }
      }
    }
    allContentstackFooter {
      nodes {
        locale
        address {
          street
          city_state_zip
          phone_number
          email
        }
        company_motto {
          motto_line_1
          motto_line_2
        }
        copyright
        locale
        title
        uid
        secondary_menu {
          link {
            href
            title
          }
        }
        main_links {
          link {
            href
            title
          }
        }
        logo {
          logo_image {
            url
          }
          logo_title
        }
      }
    }
  }
`;

export default Styletile;
